import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../components/pageheader"
import Padding from "../components/padding"
import styled from "styled-components"
import Layout from "../components/layout"
import Stappen from "../components/stappen"
import Stappen2 from "../components/stappen2"
import SEO from "../components/seo"
import { device } from "../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
}
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
    @media ${device.laptopL}{
        padding: 0 20px;
        
    }

    @media ${device.mobileL}{
        padding: 0 20px;
    }

`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li{
        font-weight: 600;
        width: 50%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Subheader = styled.h2`
    padding-top: 20px;
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);

    @media ${device.laptopL}{
        padding: 20px 20px 10px 20px;
    }
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`

    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding-bottom: 60px;
        
    }

    @media ${device.mobileL}{
        padding-bottom: 60px;
    }

    
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`
const FlexdividerR = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column-reverse;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: ... | ... | ... |;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "over-ons-banner.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000 maxHeight: 200 cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        visieImage: file(relativePath: { eq: "over-ons/missie.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          belofteImage: file(relativePath: { eq: "over-ons/belofte.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />, <Img className={className} fluid={data.visieImage.childImageSharp.fluid} />, <Img className={className} fluid={data.belofteImage.childImageSharp.fluid} />]
    return image[something];
    }


const Overons = () => (
    
  <Layout>
    <SEO title="Recycling" />
    <PageHeader title="Over Ons" bannerimage={<Headerimage something="0"/>}>
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink aria-current="page" href="/Over Ons/" onClick={handleClick}>
            Over Ons
          </ Crumblink>
        </ Styledcrumbs>
    </ PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                        <Subheader>
                            Visie
                        </Subheader>
                        <Paragraph>
                            Ons huidig lineaire economisch model is gebaseerd op &#39;groei&#39;. We zijn gaan geloven dat een
                            groeiende economie belangrijker is dan een duurzame stabiele economie. Wat hebben de
                            volgende generaties aan “onze” maximale economische groeipercentages en bedrijfswinsten
                            als zij in 2050 te kampen hebben met een opgewarmde aarde, zeespiegelstijging, extreme hitte,
                            gesmolten poolkappen, overstromingen, schade aan biodiversiteit en uitgestorven diersoorten
                            door klimaatverandering en milieuvervuiling.<br/><br/>
                            Wij zijn ervan overtuigd dat als we doorgaan met ons huidige economisch groeimodel, het
                            natuurlijk evenwicht in de toekomst in gevaar zal komen door. Als onze generatie niets
                            verandert zullen grondstoffen, energiebronnen en voedsel- en watervoorziening zwaar onder
                            druk komen te staan, met grote gevolgen voor de gezondheid en veiligheid van de mens.
                        </ Paragraph>

                </Vertpad>
                <Vertpad>
                    <FlexdividerR>
                        <div>
                        <Subheader>
                            Missie
                        </Subheader>
                        <Paragraph>
                            Onze missie is het stimuleren van een schone wereld, die ook in de toekomst leefbaar is voor
                            mens en dier. Wij zijn ervan overtuigd dat de verspilling en de uitputting van natuurlijke
                            grondstoffen moet worden tegengegaan. Wij willen hier aan bijdragen door gezamenlijk met
                            organisaties het hergebruik van overtollige ICT apparatuur te maximaliseren. Wij zijn
                            gecommitteerd aan het genereren van maximale restwaarde uit uw overtollige ICT apparatuur
                            door onze bewezen hergebruik programma’s.
                        </ Paragraph>
                        </ div>
                        <div>
                            <Headerimage something="1" />
                        </ div>
                    </ FlexdividerR>
                </Vertpad>
                <Vertpad>
                    <Flexdivider>
                        <div>
                            <Headerimage something="2" />
                        </ div>
                        <div>
                            <Subheader>
                                Onze belofte
                            </Subheader>
                            <Paragraph>
                                Wij geloven in de transitie van een lineaire economie naar een circulaire economie. In de
                                circulaire economie worden nauwelijks nieuwe grondstoffen gedolven, wordt geen afval
                                geproduceerd, omdat grondstoffen en producten steeds opnieuw worden gebruikt. Wij leveren
                                een bijdrage aan de circulaire economie in het hergebruik van ICT apparatuur. Wij nemen de
                                verantwoordelijkheid voor de afvoer van uw ICT apparatuur. Wij ontzorgen uw organisatie
                                door een veilig, secuur, transparant en betrouwbaar verwerkingsproces en garanderen u
                                maximaal hergebruik en restwaarde!
                            </ Paragraph>
                        </ div>

                    </ Flexdivider>
                </Vertpad>
                <Vertpad>

                </Vertpad>

            </Containerpad>

        </Maincontent>
    <Padding/>
  </Layout> 
)

export default Overons


